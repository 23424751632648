import React, { useRef, Suspense, useState } from "react";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./utils/auth";
import AppRouter from "./AppRouter";
import axios from "axios";
import authService from "./services/auth/auth.service";
import { ErrorResponse, HttpResponse } from "./services/base/base.dto";
import { TokenDto } from "./services/auth/auth.dto";
import "./utils/i18n";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/styles/layout.scss";

import { Toast, ToastMessage } from "primereact/toast";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { LayoutProvider } from "./components/context/LayoutContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfirmDialog } from "primereact/confirmdialog";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

var failedUrls: any = {};

const Loader = () => {
  const { t } = useTranslation();
  return (
    <div
      className="flex align-items-center justify-content-center w-screen h-screen"
      fetchpriority="high"
    >
      <ProgressSpinner />
    </div>
  );
};

interface AxiosContextType {
  showMessage: (
    message: string,
    type: "success" | "info" | "warn" | "error" | undefined
  ) => void;
}

let AxiosContext = React.createContext<AxiosContextType>(null!);

export function useAxiosContext() {
  return React.useContext(AxiosContext);
}

function AxiosProvider({ children }: { children: React.ReactNode }) {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();
  const auth = useAuth();
  let navigate = useNavigate();
  const [ran, setRan] = useState(false);
  const [blocked, setBlocked] = useState<boolean>(false);

  if (!ran) {
    const showErrors = (errorResponse: ErrorResponse) => {
      var toasts: ToastMessage[] = [];
      for (let i = 0; i < errorResponse.errors.length; i++) {
        const error = errorResponse.errors[i];
        toasts.push({
          severity: "error",
          summary: t("Sorry"),
          detail: (
            <>
              <span>{error.message}</span>
              {errorResponse.errorId ? (
                <>
                  <br />
                  <span>CODE: {errorResponse.errorId}</span>
                </>
              ) : null}
            </>
          ),
          life: 5000,
        });
      }

      toast?.current?.show(toasts);
    };

    axios.interceptors.request.use(
      (config) => {
        if (config.blockUI) {
          setBlocked(true);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        delete failedUrls[response.request.responseURL];
        if (response.config.blockUI) {
          setBlocked(false);
        }
        return response;
      },
      (error) => {
        if (error.config.blockUI) {
          setBlocked(false);
        }
        if (error.response.status === 401) {
          if (failedUrls[error.request.responseURL]) {
            navigate("/login", { replace: true });
          } else {
            failedUrls[error.request.responseURL] = 1;
            var user = auth.getUser();
            const data = {
              accessToken: user.accessToken,
              refreshToken: user.refreshToken,
            };
            return authService
              .RefreshToken(data)
              .then((response: HttpResponse<TokenDto>) => {
                if (response.result) {
                  auth.saveUser(response.result, true);
                  axios.defaults.headers.common["Authorization"] =
                    "Bearer " + response.result.accessToken;
                  error.config.headers["Authorization"] =
                    "Bearer " + response.result.accessToken;
                }

                return axios(error.config);
              });
          }
        } else if (error.response.status === 403) {
          auth.deleteUser();
          navigate("/login", { replace: true });
        } else if (
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          if (error.response?.data && !error.config.overrideError) {
            showErrors(error.response?.data);
          }
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );

    setRan(true);
  }

  const pageLoader = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center w-screen h-screen">
        <span className="font-bold text-white text-6xl">{t("Loading")}</span>
        <ProgressSpinner />
      </div>
    );
  };

  let showMessage = (
    message: string,
    type: "success" | "info" | "warn" | "error" | undefined
  ) => {
    var summary = "";
    if (type === "success") {
      summary = t("Successful");
    } else if (type === "error") {
      summary = t("Sorry");
    } else if (type === "warn") {
      summary = t("Warning");
    } else if (type === "info") {
      summary = t("Information");
    }

    toast?.current?.show({
      severity: type,
      summary: summary,
      detail: message,
      closable: true,
      className: "custom-toast",
      life: 5000,
    });
  };

  let value = { showMessage };

  return (
    <AxiosContext.Provider value={value}>
      <Toast ref={toast} position="bottom-right" />
      <ConfirmDialog />
      <BlockUI blocked={blocked} fullScreen template={pageLoader}>
        {children}
      </BlockUI>
    </AxiosContext.Provider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Suspense fallback={<Loader />}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AxiosProvider>
            <LayoutProvider>
              <AppRouter />
            </LayoutProvider>
          </AxiosProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Suspense>
);
