import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { TokenDto } from "../services/auth/auth.dto";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useCookies } from "react-cookie";
import i18n, { languageCodes } from "./i18n";

interface UserInfo {
  accessToken: string;
  refreshToken: string;
  role: string;
  exp: Date;
  id: string;
  name: string;
  email: string;
  permissions: string[];
  tenants: Tenant[];
  tenantPermissions: { [propKey: string]: string[] };
  selectedTenant: Tenant | undefined;
  photo?: string;
  phoneNumber?: string;
  phoneCode?: string;
  title?: string;
  languageId: string;
}

export interface Tenant {
  id: number;
  name: string;
  logo?: string;
  role?: string;
}

interface AuthContextType {
  getUser: () => UserInfo;
  saveUser: (
    input: TokenDto,
    remember: boolean,
    forceTenantId?: number | undefined
  ) => void;
  deleteUser: () => void;
  setTenant: (tenant: Tenant) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [cookies, setCookie, removeCookie] = useCookies(["X-Tenant-ID"]);

  var accessToken = "";
  var refreshToken = "";
  var role: string;
  var exp = new Date();
  var id = "";
  var name = "";
  var email = "";
  var permissions: string[] = [];
  var tenantPermissions: { [propKey: string]: string[] } = {};
  var tenants: Tenant[] = [];
  var selectedTenant: Tenant | undefined;
  var photo = "";
  var phoneNumber = "";
  var phoneCode = "";
  var title = "";
  var languageId = "";

  let parseUser = (forceTenantId: number | undefined = undefined) => {
    accessToken = localStorage.getItem("accessToken") || "";
    refreshToken = localStorage.getItem("refreshToken") || "";

    if (accessToken.length === 0) {
      accessToken = sessionStorage.getItem("accessToken") || "";
    }

    if (refreshToken.length === 0) {
      refreshToken = sessionStorage.getItem("refreshToken") || "";
    }

    if (accessToken.length > 0) {
      const decoded = jwt_decode(accessToken) as any;
      role =
        decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

      exp = new Date(decoded["exp"] * 1000);

      id = decoded["sub"];

      name =
        decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];

      email =
        decoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
        ];

      photo = decoded["Photo"];
      phoneNumber = decoded["PhoneNumber"];
      phoneCode = decoded["PhoneCode"];
      title = decoded["Title"];
      languageId = decoded["LanguageId"];

      let rawPermissions = decoded["Permission"];
      if (rawPermissions) {
        permissions = rawPermissions;
      }
      let rawTenants = decoded["Tenant"];
      if (rawTenants) {
        if (Array.isArray(rawTenants)) {
          tenants = rawTenants.map((x) => JSON.parse(x));
        } else {
          tenants = [JSON.parse(rawTenants)];
        }

        let initialTenantId = cookies["X-Tenant-ID"];

        if (forceTenantId) {
          initialTenantId = forceTenantId;
        }

        var tenantFilter = tenants.filter((x) => x.id === initialTenantId);

        if (tenantFilter && tenantFilter.length > 0) {
          setTenant(tenantFilter[0]);
        } else {
          setTenant(tenants[0]);
        }
      } else {
        tenants = [];
        setTenant(undefined);
      }

      let rawTenantPermissions = decoded["TenantPermission"];
      if (rawTenantPermissions) {
        if (Array.isArray(rawTenantPermissions)) {
          tenantPermissions = Object.assign(
            {},
            ...rawTenantPermissions.map((x) => JSON.parse(x))
          );
        } else {
          tenantPermissions = JSON.parse(rawTenantPermissions);
        }
      } else {
        tenantPermissions = {};
      }
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    axios.defaults.headers.common["Accept-Language"] =
      languageCodes[i18n.language as keyof typeof languageCodes];
  };

  let saveUser = (
    input: TokenDto,
    remember: boolean,
    forceTenantId: number | undefined = undefined
  ) => {
    if (remember) {
      if (input.accessToken) {
        localStorage.setItem("accessToken", input.accessToken);
      }
      if (input.refreshToken) {
        localStorage.setItem("refreshToken", input.refreshToken);
      }
    } else {
      if (input.accessToken) {
        sessionStorage.setItem("accessToken", input.accessToken);
      }
      if (input.refreshToken) {
        sessionStorage.setItem("refreshToken", input.refreshToken);
      }
    }

    parseUser(forceTenantId);
  };

  let deleteUser = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    parseUser();
  };

  let getUser = () => {
    return {
      accessToken,
      refreshToken,
      role,
      exp,
      id,
      name,
      email,
      permissions,
      tenants,
      tenantPermissions,
      selectedTenant,
      photo,
      phoneNumber,
      phoneCode,
      title,
      languageId
    };
  };

  let setTenant = (tenant: Tenant | undefined) => {
    if (tenant) {
      axios.defaults.headers.common["X-Tenant-ID"] = tenant.id;
      setCookie("X-Tenant-ID", tenant.id);
      selectedTenant = tenant;
    } else {
      delete axios.defaults.headers.common["X-Tenant-ID"];
      removeCookie("X-Tenant-ID");
      selectedTenant = undefined;
    }
  };

  let value = { getUser, saveUser, deleteUser, setTenant };

  parseUser();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({
  permissions,
  children,
  redirectTo = "/accessdenied",
}: {
  permissions: string[];
  redirectTo?: string;
  children: JSX.Element;
}) {
  let auth = useAuth();
  let location = useLocation();

  var user = auth.getUser();
  if (user.accessToken.length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  let isAllowed = false;
  if (permissions && permissions.length > 0) {
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      if (user.permissions.includes(permission)) {
        isAllowed = true;
        break;
      } else if (
        user.selectedTenant &&
        user.tenantPermissions &&
        user.tenantPermissions[user.selectedTenant.id]?.includes(permission)
      ) {
        isAllowed = true;
        break;
      }
    }
  } else {
    isAllowed = true;
  }

  if (isAllowed) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace={false} />;
  }
}
