import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import "i18next";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

var i18nextLng = localStorage.getItem("i18nextLng");
if (!i18nextLng) {
  localStorage.setItem("i18nextLng", "tr");
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    // lng: "tr",
    debug: false,
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    cache: {
      enabled: true,
      expirationTime: 3600000,
    },
  });
export default i18n;

export const languageCodes = {
  en: "en-US",
  tr: "tr-TR",
  zh: "zh-CN",
};
